<route>
{
  "meta": {
    "permission": [
      "matrix.view_matrixtemplate"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <i-table
      :title="$tc('matrix', 2)"
      :headers="headers"
      app="matrix.matrixtemplate"
      api="matrix.template"
      :opt="{ matrix_use: 'product' }"
      @click:create="changeRoute({ pk: 'create' })"
      @click:edit="changeRoute"
      :otherBtn="matrixTemplateBtn.add"
    >
      <template v-slot:otherBtn="{ item }">
        <i-btn
          :title="$tc('clone', 1)"
          outlined
          classes="mx-1"
          icon="fa-clone"
          color="secondary"
          @click="clone(item.pk, 'product')"
        />
      </template>
    </i-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text:
            this.matrixTemplateBtn.add ||
            this.matrixTemplateBtn.delete ||
            this.matrixTemplateBtn.edit
              ? this.$t('action')
              : '',
          value:
            this.matrixTemplateBtn.add ||
            this.matrixTemplateBtn.delete ||
            this.matrixTemplateBtn.edit
              ? 'actions'
              : '',
          align: 'center',
          width: '160px'
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    matrixTemplateBtn() {
      return {
        add: this.getPermissions([`matrix.add_matrixtemplate`]) || this.isAdmin,
        delete:
          this.getPermissions([`matrix.delete_matrixtemplate`]) || this.isAdmin,
        edit:
          this.getPermissions([`matrix.change_matrixtemplate`]) || this.isAdmin
      }
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'matrix-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    async clone(pk, use) {
      const response = await this.$api.matrix.template.show({
        pk: pk,
        opt: { params: { clone: use } }
      })
      this.changeRoute({ pk: response.data.pk })
    }
  }
}
</script>
